<template>
    <div class="list-view">
        <page-title>
            <!-- <el-button
                @click="addRow"
                type="primary"
                icon="el-icon-plus"
                :disabled="fetchingData || downloading"
                class="filter-item"
            >
                新增
            </el-button> -->
            <el-button @click="download" icon="el-icon-upload2" :loading="downloading" :disabled="fetchingData"
                class="filter-item">
                导出
            </el-button>
            <el-button @click="batch" class="filter-item">
                批量转移
            </el-button>
        </page-title>
        <div class="filters-container">
            <el-form :model="formData" :inline="true">
                <el-form-item label="创建时间">
                    <created-at-picker v-model="formData.createdAt" @input="initData" name="创建"></created-at-picker>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="formData.phone" placeholder="手机号" @input="initData" clearable=""></el-input>
                </el-form-item>
                <el-form-item label="藏品名称">
                    <el-input v-model="formData.name" placeholder="藏品名称" @input="initData" clearable=""></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="formData.userId" placeholder="用户ID" @input="initData" clearable=""></el-input>
                </el-form-item>
                <el-form-item label="藏品ID">
                    <el-input v-model="formData.collectionId" placeholder="藏品ID" @input="initData"
                        clearable=""></el-input>
                </el-form-item>
                <el-form-item label="资产ID">
                    <el-input v-model="formData.id"       placeholder="资产ID"  @input="initData"  clearable="" ></el-input>
                </el-form-item>
                <el-form-item label="资产编号">
                    <el-input v-model="formData.number"       placeholder="资产编号"  @input="initData"  clearable="" ></el-input>
                </el-form-item>
                <el-form-item label="订单ID">
                    <el-input v-model="formData.orderId" placeholder="订单ID" @input="initData" clearable=""></el-input>
                </el-form-item>

                <el-form-item label="订单状态">
                    <el-select v-model="formData.status" @input="initData" clearable="">
                        <el-option v-for="(value, key) in assetStatusMap" :label="value" :value="key"
                            :key="key"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-input placeholder="搜索..." v-model="search" clearable class="filter-item search"
                @keyup.enter.native="initData">
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table :data="tableData" row-key="id" ref="table" header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell" row-class-name="table-row" cell-class-name="table-cell"
            :height="tableHeight" v-loading="fetchingData">
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="userId" label="用户ID"> </el-table-column>
            <el-table-column prop="phone" label="持有者手机号" width="100"> </el-table-column>
            <el-table-column prop="id" label="资产ID"> </el-table-column>
            <el-table-column prop="number" label="资产编号"> </el-table-column>
            <el-table-column prop="collectionId" label="藏品ID" width="140"> </el-table-column>
            <el-table-column prop="name" label="名称" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <el-table-column prop="sellPrice" label="转让价格"> </el-table-column>
            <el-table-column prop="orderId" label="订单ID"> </el-table-column>
            <!-- <el-table-column prop="minter" label="铸造者"> </el-table-column>
            <el-table-column prop="minterId" label="铸造者ID"> </el-table-column>
            <el-table-column prop="minterAvatar" label="铸造者头像"> </el-table-column>
            <el-table-column prop="pic" label="图片"> </el-table-column> -->
            <el-table-column prop="tokenId" label="tokenId"> </el-table-column>
            <el-table-column prop="txHash" label="链上hash"> </el-table-column>
           <!-- <el-table-column prop="gasUsed" label="消耗GAS"> </el-table-column>-->

            <!-- <el-table-column prop="ipfsUrl" label="购买hash"> </el-table-column> -->
            <!-- <el-table-column prop="outTxHash" label="转让hash"> </el-table-column> -->
            <!-- <el-table-column prop="outOrderId" label="转让订单ID"> </el-table-column> -->
            <el-table-column prop="status" label="支付状态" width="100">
                <template slot-scope="scope">
                    <el-tag :type="assetStatusMap[scope.row.status].type" v-if="assetStatusMap[scope.row.status]">{{
                assetStatusMap[scope.row.status] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createdAt" label="购买时间" width="140"> </el-table-column>
            <el-table-column prop="outTime" label="交易时间" width="140"></el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="200">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>查看</el-button>
                    <el-button type="primary" @click="open(row)">资产转移</el-button>
                    <!--                        <el-button type="text" @click="open">资产转移</el-button>-->
                    <!-- <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <!-- <div class="multiple-mode-wrapper">
                <el-button v-if="!multipleMode" @click="toggleMultipleMode(true)">批量编辑</el-button>
                <el-button-group v-else>
                    <el-button @click="operation1">批量操作1</el-button>
                    <el-button @click="operation2">批量操作2</el-button>
                    <el-button @click="toggleMultipleMode(false)">取消</el-button>
                </el-button-group>
            </div> -->
            <el-pagination background @size-change="onSizeChange" @current-change="onCurrentChange" :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalElements">
            </el-pagination>
        </div>
        <el-dialog title="批量转移" :visible.sync="batchShow">
            <el-form :model="batchParams" :rules="batchRules" ref="batchForm">
                <el-form-item prop="phoneString" label="转移方手机号">
                    <el-input v-model="batchParams.phoneString" type="textarea" :autosize="{ minRows: 3, maxRows: 20 }"
                        placeholder="可输入多个手机号，用空格或回车分隔"></el-input>
                </el-form-item>
                <el-form-item prop="collectionId" label="转移藏品">
                    <collection-search v-model="batchParams.collectionId"></collection-search>
                </el-form-item>
                <el-form-item prop="num" label="转移数量">
                    <el-input-number v-model="batchParams.num"></el-input-number>
                </el-form-item>
                <el-form-item prop="toUserId" label="接收方">
                    <el-input v-model="batchParams.toUserId" placeholder="请输入用户手机号或ID"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitBatch" :loading="loading">提交</el-button>
                <el-button @click="() => batchShow = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';
import { assetStatusMap } from '@/utils/constant.js';

export default {
    name: 'AssetList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/asset/all',
            downloading: false,
            assetStatusMap,
            formData: {
                createdAt: '',
                userId: '',
                name: '',
                collectionId: '',
                orderId: '',
                status: '',
                phone: '',
            },
            batchRules: {
                phoneString: [{ required: true, message: '请输入转移方手机号' }],
                toUserId: [{ required: true, message: '请输入接收方手机号或用户ID' }],
                collectionId: [{ required: true, message: '请选择转移藏品' }],
            },
            batchParams: {
                phoneString: '',
                phones: [],
                num: 0,
                toUserId: '',
                collectionId: ''
            },
            dialogVisible: false,
            batchShow: false,
            loading: false
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        submitBatch() {
            this.$refs.batchForm.validate(() => {
                this.batchParams.phones = []
                this.loading = true
                this.$http.post('/user/searchByPhoneAdmin', { phone: this.batchParams.phoneString }).then(res => {
                    this.batchParams.phones = this.batchParams.phones.concat(res.users.filter(i => !this.batchParams.phones.find(e => e.id === i.id)));
                    let userArray = []

                    this.batchParams.phones = this.batchParams.phones.map((item, index) => {
                        return item.phone
                    })
                    if (res.notFound && res.notFound.length) {
                        this.$alert('未找到以下手机对应的用户：<br>' + res.notFound.join('、'), '', {
                            dangerouslyUseHTMLString: true
                        });
                        this.loading = false
                        return
                    }
                    this.$http.post('/asset/batchTransfer', this.batchParams, { body: 'json' }).then(res => {
                        this.loading = false
                        this.$message.success(res)
                        this.batchShow = false
                    }).catch(err => {
                        this.$message.error(err.error)
                        this.loading = false
                    })
                })

            })
        },
        batch() {
            this.batchShow = true
            this.batchParams = {
                phoneString: '',
                phones: [],
                num: 0,
                toUserId: '',
                collectionId: ''
            }
        },
        beforeGetData() {
            return { search: this.search, query: { del: false, ...this.formData } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/assetEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/assetEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            const params = {
                size: 10000,
                search: this.search,
                query: {
                    createdAt: this.formData.createdAt,
                    userId: this.formData.userId,
                    collectionId: this.formData.collectionId,
                    orderId: this.formData.orderId,
                    status: this.formData.status,
                    phone: this.formData.phone,
                    name: this.formData.name
                }

            };
            this.$axios
                .post('/asset/excel', params, {
                    responseType: 'blob'
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', decodeURIComponent(res.headers['content-disposition'].split('filename=')[1]));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/asset/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        },
        open(row) {
            this.$prompt('请输入手机号', '资产转移', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({ value }) => {
                let data = {
                    "phone": value,
                    "id": row.id
                };

                this.saving = true;
                this.$http
                    .post('/asset/adminTransfer', data, { body: 'json' })
                    .then(res => {
                        // this.saving = false;
                        this.$message.success('成功');
                        this.getData();
                    })
                    .catch(e => {
                        console.log(e);
                        this.saving = false;
                        this.$message.error(e.error);
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        }
    }
};
</script>
<style lang="less" scoped></style>
